.container {
  margin: 0 auto;
  padding: 10px 0 0;
  max-width: 960px;
  width: 100%;
  height: auto;
}

.pricing-table {
  display: flex;
  justify-content: center;
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border: 2px solid #3097d1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  width: 100%;
  border-radius: 20px;
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 90%;
}

.pricing-header {
  border-radius: 19px 19px 0 0;
  color: white;
  font-weight: bolder;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.pricing-features {
  color: #016ff9;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 30px 20px 15px 20px;
  padding: 10px;
  min-height: 350px;
}

.pricing-features-item {
  border-top: 2px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px 0;
  display: flex;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}
.pricing{
  margin-top: auto;
}
.pricing-price {
  color: #109f65;
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 20px;
}

.pricing-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #facf00;
  border-radius: 0 0 19px 19px;
  color: #301000;
  font-size: 20px;
  width: 100%;
  min-height: 50px;
  padding: 5px 5px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #1f6996;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: #269aff;
}

@media screen and (max-width: 500px) {
  .pricing-table {
    flex-direction: column;
  }
  .pricing-plan {
    margin: 10px;
  }
}
