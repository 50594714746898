.cont {
  display: flex;
  justify-content: center;
  width: auto;
  flex-wrap: wrap;
}

.cardrow {
  width: 487px;
 height: max-content;
 
}

.cardrowimg {
  height: 80%;
  object-fit: 'contain';
  border-radius: 20px 20px 0 0;
}
.cardrowdesc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(180deg, #242934 0%, #141414 100%);
}
.descrow-para {
  width: 80%;
}

@media screen and (max-width: 800px) {
  .cont {
    justify-content: center;
    flex-wrap: wrap;
  }
  .cardrow {
    width: 100%;
    height: 50%;
  }
  .cardrowimg {
    width: 100%;
    object-fit: contain;
  }
  .cardrowdesc {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
  }
  .descrow-para {
    width: 90%;
  }
}
